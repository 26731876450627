import React from "react";
import { graphql } from "gatsby";
import { PrimoShowcase } from "./PrimoShowcase";
import {
  KontentComponentLayout,
  KontentComponentSettings,
  StringValue,
  KontentMediaImage,
} from "../../../types";
import { formatImages } from "../../../lib/images";
import { getMultiChoice } from "../../../lib/multichoice";
import { formatLinks } from "../../../lib/links";

interface Props extends KontentComponentSettings, KontentComponentLayout {
  title_text: StringValue;
  title_link: StringValue;
  default_thumbnail: KontentMediaImage;
  primo_search_query_url: StringValue;
}

const PrimoShowcaseContainer: React.FC<Props> = ({
  component_layout__component_width,
  component_settings__anchor_name,
  component_settings__hide_on_mobile,
  link,
  ...props
}) => {
  const defaultThumbnail = formatImages(
    props.default_thumbnail.value[0]?.elements
  )?.largeImage;
  const componentWidth = getMultiChoice(component_layout__component_width);
  const cta = formatLinks(link?.modular_content)[0];

  return (
    <PrimoShowcase
      titleText={props.title_text.value}
      titleLink={props.title_link.value}
      defaultThumbnail={defaultThumbnail}
      queryUrl={props.primo_search_query_url.value}
      width={componentWidth}
      anchor={component_settings__anchor_name.value}
      ctaHref={cta?.href}
      ctaTarget={cta?.target}
      ctaLabel={cta?.label}
      hideOnMobile={
        getMultiChoice(component_settings__hide_on_mobile) === "yes"
      }
      {...props}
    ></PrimoShowcase>
  );
};

export default PrimoShowcaseContainer;

export const fragmentPrimoShowcaseContainer = graphql`
  fragment PrimoShowcase on kontent_item_component___primo_showcase {
    elements {
      title_text {
        value
      }
      title_link {
        value
      }
      default_thumbnail {
        value {
          ...kontentMediaImage
        }
      }
      primo_search_query_url {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      link {
        modular_content {
          ...kontentRichTextLink
        }
      }
      component_settings__anchor_name {
        value
      }
      component_layout__component_width {
        value {
          codename
        }
      }
    }
  }
`;
